/* Switch.css */
.switch {
  width: 50px;
  height: 25px;
  background-color: #ccc;
  border-radius: 25px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.switch-on {
  background-color: #4caf50;
}

.switch-off {
  background-color: #ccc;
}

.switch-handle {
  width: 23px;
  height: 23px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: left 0.3s ease;
}

.switch-on .switch-handle {
  left: 26px;
}
