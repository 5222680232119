@tailwind base;
@tailwind components;
@tailwind utilities;

/* Importar la fuente Cascadia Code */
@font-face {
  font-family: 'Cascadia Code';
  src: url('../public/fonts/CascadiaCode.woff2') format('woff2'),
       url('../public/fonts/CascadiaCodeItalic.woff2') format('woff2'),
       url('../public/fonts/CascadiaCodeNF.woff2') format('woff2'),
       url('../public/fonts/CascadiaCodeNFItalic.woff2') format('woff2'),
       url('../public/fonts/CascadiaCodePL.woff2') format('woff2'),
       url('../public/fonts/CascadiaCodePLItalic.woff2') format('woff2'),
       url('../public/fonts/CascadiaMono.woff2') format('woff2'),
       url('../public/fonts/CascadiaMonoItalic.woff2') format('woff2'),
       url('../public/fonts/CascadiaMonoNF.woff2') format('woff2'),
       url('../public/fonts/CascadiaMonoNFItalic.woff2') format('woff2'),
       url('../public/fonts/CascadiaMonoPL.woff2') format('woff2'),
       url('../public/fonts/CascadiaMonoPLItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Cascadia Code', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Cascadia Code', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Clase para fijar el ancho de la fecha */
.fixed-width {
  min-width: 5.9rem; /* Ajusta el valor según sea necesario */
  text-align: right;
  white-space: nowrap;
}

/* Efecto de transición del borde y del texto */
.sidebar-item {
  position: relative;
  overflow: hidden;
}

.sidebar-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0; /* Comienza con un ancho de 0 */
  height: 100%;
  background-color: var(--color-tertiary); /* Usamos la variable CSS */
  transition: width 0.3s ease-in-out; /* Transición del ancho */
}

.sidebar-item:hover::before {
  width: 5px; /* Expande a 5px cuando se hace hover */
}

.sidebar-text {
  position: relative;
  display: inline-block;
  background: linear-gradient(to left, currentColor 50%, var(--color-tertiary) 50%);
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 0.3s ease-in-out;
  color: currentColor;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sidebar-item:hover .sidebar-text {
  background-position: 0;
  /* NOTA: Eliminado el text-fill-color para evitar interferencias */
}

/* Selected item */
.selected-sidebar-item {
  border-left: 4px solid var(--color-tertiary); /* Mantenemos el borde izquierdo en color terciario */
  background-color: rgba(222, 88, 210, 0.1); /* Un fondo ligeramente diferente para el post seleccionado */
}

.selected-sidebar-text {
  background: linear-gradient(to left, var(--color-tertiary) 50%, var(--color-tertiary) 50%);
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 0.3s ease-in-out;

}
